<template>
  <div>
    <div
      class="bg-gray-200 dark:bg-gray-600 dark:text-white rounded-xl px-4 pb-4 space-y-2"
    >
      <h1>About</h1>
      <div>NFT 개발로 창을음 준비중입니다. 진행상황의 기록을 남겨둡니다.</div>
      <!-- <h4>진행중</h4>
      <div class="pl-4">심는닭 / PlantosyDak 컨텐츠 개발중입니다.</div>

      <h4>계획중</h4>
      <div class="pl-4">창작자 도움 프로젝트 구상중입니다.</div>
      <div class="pl-4">창작도구 구상중입니다.</div> -->

      <h2>history</h2>
      <div class="space-y-4">
        <div
          class="bg-white rounded-xl shawdow p-4 space-y-4"
          v-for="data in datas"
          :key="data.key"
        >
          <div class="flex justify-left">
            <div class="bg-gray-500 rounded-full px-2 pt-1 text-white text-xs">
              {{ data.date }}
            </div>
          </div>
          <div class="font-jalnan text-black">{{ data.title }}</div>
          <div class="text-sm text-gray-500" v-html="md(data.md)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const first_nft_url = this.$store.get_opensea_url(10);
    return {
      datas: [
        {
          key: 6,
          date: "2022-04-17",
          title: "업데이트 알림",
          md: `
변경점
- 알낳기 버그가 있던 스마트컨트렉트를 교체하였습니다.
  - 과거 NFT 는 정리해주세요
- 하단에 디스코드와 트레일로 아이콘이 추가되었습니다.
- 웹게임 기술 데모를 제거하였습니다. 본격적으로 제작을 진행합니다.
          `.trim(),
        },
        {
          key: 6,
          date: "2022-02-25",
          title: "업데이트 알림",
          md: `
변경점
- 심는닭 페이지에 공지사항을 올렸습니다.
- 카이카스(Kaikas) 외 메타마스크(Metamask) 로그인을 지원합니다.
- 다크모드 버튼을 수정했습니다.
- kaboom.js 기술 데모를 게임올렸습니다.
  - 모바일에서 플레이 가능한게 특징입니다.
  - 사운드가 재생됩니다.
          `.trim(),
        },
        {
          key: 5,
          date: "2022-01-22",
          title: "PlantosyDak 판매 시작 판매가격은 1 klay",
          md: `
첫번째로 판매된 NFT 는 10번입니다.  
[OpenSea 링크](${first_nft_url})
          `.trim(),
        },
        {
          key: 4,
          date: "2022-01-15",
          title: "SIDNFT 의 첫번째 수직원 발생",
          md: `
NFT 판매된 [OpenSea](https://opensea.io/assets/klaytn/0x1996aebd90de174d48e5f3716163577f66a71d2b/72) 
이 NFT 는 [클레이튼 강좌](https://www.youtube.com/watch?v=TyYV7h9knkw&t=862s)
에서 소개된 NFT 이였습니다.  
***판매된 후***에는 감사의 의미로 이미지를 별도로 제작하였습니다.  
이 이미지에는 SIDNFT 의 첫번째 수익원 이라는 문구가 있습니다.
![NFT72](/img/about/img_72_upgrade.png)  
          `.trim(),
        },
        {
          key: 3,
          date: "2021-12-30",
          title: "클레이튼 매안냇애서 PotDak2 배포",
          md: `
[klayscope](https://scope.klaytn.com/account/0xa2dFA0960e7D5a99170dC77e00533A43A9CB2CFc?tabId=txList)
          `.trim(),
        },
        {
          key: 2,
          date: "2021-12-14",
          title: "클레이튼 매안냇애서 NFT 테스트 성공",
          md: `
          `.trim(),
        },
        {
          key: 1,
          date: "2021-10-26",
          title: "SIDNFT 웹 서비스 시작",
          md: `
          `.trim(),
        },
      ],
    };
  },
  mounted() {
    console.log("about mounted");
    this.$store.commitRoot("set_ui", this.$route.fullPath);
  },
};
</script>
